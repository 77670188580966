import React from 'react';
import HighlightButton from '../HighlightButton/HighlightButton';

import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';

import styles from '../../css/Home.module.css';

class Home extends React.Component {
  handleButtonClick = (buttonTitle) => {
    if (analytics) {
      logEvent(analytics, 'code_home_button_click', {
        button_title: buttonTitle
      });
    }
  };

  render() {
    return (
      <div className="mainContainer centeredVertically">
        {/* <div className="profileImageContainer">
          <img shadow={5} className="profileImage" alt="Lawrence Luo portrait" src={"/static/img/law_cropped.png"}></img>
        </div> */}

        <div className="centeredHorizontallyOnMobile textCenteredOnMobile">
          <div className={styles.superscript}><em>greetings from</em></div>
          <h1>LAWRENCE LUO</h1>
          <p className={styles.description} style={{ paddingBottom: 10 }}>
            I create web and mobile apps. Let&#39;s build something!
          </p>

          <div className={`ctaButtonsContainer ${styles.centeredOnMobile}`}>
            {/* <span style={{ marginRight: 10, paddingBottom: 10 }}>
              <HighlightButton
                title={"BROWSE WORK"}
                onClick={() => {
                  this.handleButtonClick('BROWSE WORK');
                  window.location = "/work";
                }}
              />
            </span> */}

            <span style={{ marginRight: 10, paddingBottom: 10 }}>
              <HighlightButton
                title={"TEACHING"}
                onClick={() => {
                  this.handleButtonClick('TEACHING');
                  window.location = "/teaching";
                }}
              />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Home;
