import React from 'react';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

class Teaching extends React.Component {
  startDate = new Date('2019-01-01');

  handleLinkClick = (linkType) => {
    if (analytics) {
      logEvent(analytics, 'teaching_link_click', {
        link_type: linkType
      });
    }
  };

  render() {
    return (
      <div className="mainContainer centeredVertically">
        <h1>TEACHING</h1>
        <p>
          My goal is to empower my students with the skills and confidence they need to transform their ideas into reality. I have mentored students of all ages and levels, and have {new Date().getFullYear() - this.startDate.getFullYear()}+ years of teaching experience spread across computer science & coding subjects.
        </p>

        <p style={{ marginTop: 10 }}>
          As a seasoned developer, I work daily on web & mobile apps with HTML, CSS, Javascript, React, React-Native, and Flask. I also use Python, Java, and AI programs regularly. If you are interested in any of these subjects, or want to learn a subject not listed here, please 
           <a href="/contact" rel="noopener noreferrer" target="_blank" onClick={() => this.handleLinkClick('contact')}> contact me</a> or visit my <a href={"https://www.wyzant.com/Tutors/LawrenceTutors"} rel="noopener noreferrer" target="_blank" onClick={() => this.handleLinkClick('wyzant')}>Wyzant profile</a>.
        </p>
      </div>
    )
  }
}

export default Teaching;

/*
Greetings! I'm Lawrence, a graduate from Boston University with a degree in Psychology & Computer Science. I have 7+ years of tutoring experience spread across Computer Science & Coding subjects for students of all levels.

My experience with software topics includes web & mobile app development with Python, HTML, CSS, Javascript, React, and React-Native. If you are interested in any of the subjects I tutor in, or are wondering if I can tutor in a specific subject not listed here, please don't hesitate to reach out and ask!

All my life, I have known the immense value of teaching. I have experience at all levels: as a volunteer for elementary school students, a private tutor for middle and high school students, a teaching assistant for divlege students, and a Wyzant tutor for adult learners. These experiences have shaped my passion for educating others and have helped me understand a wide variety of student learning styles.

My tutoring style is based around understanding the student's specific needs and providing a personalized, holistic, and enjoyable learning experience. I determine the student's reasons for learning the subject, the specific parts of the subject that interest them, and their learning style. As a cross-sectional person with interests in technology, psychology, and music, I also make sure that the concepts I teach come with the context of their real-world applications. Finally, I invest myself in my students and I expect them to take similar responsibility for their own progress.
*/
